@font-face {
    font-family: AnatolijCapital;
    font-weight: 400;
    src: url('./assets/fonts/Anatoli_ljutjuk_capital-Regular.ttf') format('truetype');
}

@font-face {
    font-family: AnatolijCursive;
    font-weight: 400;
    src: url('./assets/fonts/Anatoli_ljutjuk_cursive-Regular.ttf') format('truetype');
}